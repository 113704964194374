<template>
  <v-container class="pa-0" fluid>
    <kurcc-inspection-list :key="inspectionListComponentKey" :selected-ids.sync="selectedIds"
                           :show-select-button="adding" :filter="['status=upcoming']"
                           @change="reloadInspectionList"/>
    <kurcc-f-a-b :center="adding" :text="text" icon="mdi-text-box-plus-outline"
                 @click="addInspectionsToInspector"/>
    <kurcc-inspection-select-inspector-dialog :inspection-ids="selectedIds" :show-dialog.sync="dialog"
                                              @change="clearState"/>
  </v-container>
</template>

<script>
export default {
  name: 'KurccInspectionsPage',
  components: {
    KurccInspectionList: () => import('@/modules/app/components/KurccInspectionList'),
    KurccFAB: () => import('@/modules/app/components/KurccFAB'),
    KurccInspectionSelectInspectorDialog: () => import('@/modules/inspections/components/KurccInspectionSelectInspectorDialog')
  },
  data () {
    return {
      adding: false,
      dialog: false,
      selectedIds: [],
      inspectionListComponentKey: 0
    }
  },
  computed: {
    text () {
      return this.adding ? this.$vuetify.lang.t('$vuetify.pages.inspections.assignToBtn') : null
    },
    anyItemsSelected () {
      return this.selectedIds.length > 0
    },
    enteringAddMode () {
      return this.adding === false
    },
    exitingAddMode () {
      return this.adding === true
    }
  },
  methods: {
    clearState () {
      this.selectedIds = []
      this.adding = false
      this.reloadInspectionList()
    },
    addInspectionsToInspector () {
      if (this.enteringAddMode) {
        this.adding = true
        return
      }

      if (this.exitingAddMode) {
        if (this.anyItemsSelected) {
          this.dialog = true
        } else {
          this.adding = false
        }
      }
    },
    reloadInspectionList () {
      this.inspectionListComponentKey++
    }
  }
}
</script>

<style scoped>
/deep/ .v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 15px
}
</style>
